<template>
  <a
    id="focus-reset"
    aria-hidden
    href="javascript:void(0);"
    tabIndex="-1"
    :style="{ position: 'fixed', top: '-10000px' }"
  >
    Focus Reset
  </a>

  <a href="#main" class="skip-to-main-content">{{ $t('layout.external.skip-to-main-content') }}</a>
</template>

<style lang="scss" scoped>
.skip-to-main-content {
  z-index: 10000;
  position: absolute;
  left: -9999px;

  padding: 1rem;

  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;

  background-color: var(--white);
  color: var(--color, var(--red-basic));
  opacity: 0;
}

.skip-to-main-content:hover {
  color: var(--color, var(--gray-dark));
}

.skip-to-main-content:visited {
  color: var(--color, var(--black));
}

.skip-to-main-content:focus {
  left: 50%;

  transform: translateX(-50%);
  opacity: 1;
}
</style>
